import { useAPIAction, ConfiguratorApi } from "melco-shared-logic";

export const useDownloadOrders = () => {
  return useAPIAction(
    ConfiguratorApi,
    async (api, ids: string[], file_format?: string) => {
      const data = await api.configuratorSessionPersonalizationsDownloadsRaw({
        format: file_format,
        melcoCoreModelsPersonalizationDownloads: {
          personalization_list: ids,
        },
      });

      return {
        successMessages: [],
        data,
      };
    },
    {
      translationPrefix: "orders.actions.download",
    }
  );
};
